import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from './core/user-claim-data';

@Pipe({
  name: 'lsuUserHasRole',
  standalone: true,
})
export class HasRolePipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(roles: string[]): boolean {
    return roles.includes('*') || roles.includes(this.userService.currentOrImpersonateUser.role);
  }
}
