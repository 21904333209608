import { DestroyRef, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { interval, take } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'form[lsuFocusInvalidInput]',
  standalone: true,
})
export class FocusInvalidInputDirective {
  // sometimes a delay is required e.g. when an animation is done like expansion panels
  @Input() focusDelay = 0;
  constructor(
    private el: ElementRef,
    private destroyRef: DestroyRef,
  ) {}

  @HostListener('submit')
  onFormSubmit() {
    const invalidControl = this.el.nativeElement.querySelector('input.ng-invalid');

    if (invalidControl) {
      interval(this.focusDelay)
        .pipe(take(1), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          invalidControl.focus();
        });
    }
  }
}
