<ng-container *ngIf="label; else iconButton">
  <button type="button" mat-button [matMenuTriggerFor]="menu">
    <span class="me-2">{{ label }}</span>
    <mat-icon>format_color_fill</mat-icon>
  </button>
</ng-container>

<ng-template #iconButton>
  <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>format_color_fill</mat-icon>
  </button>
</ng-template>

<mat-menu #menu="matMenu">
  <ng-container *ngIf="!editProjectTheme; else editThemeButton">
    <button mat-menu-item (click)="onAddTheme()">
      <mat-icon>add</mat-icon>
      <span>New theme</span>
    </button>
  </ng-container>

  <ng-template #editThemeButton>
    <button mat-menu-item (click)="onEditTheme()">
      <mat-icon>edit</mat-icon>
      <span>Edit this theme</span>
    </button>
  </ng-template>

  <mat-divider></mat-divider>

  <span *ngIf="editProjectTheme" class="w-100 d-flex align-items-center p-2 border-box">Choose another theme:</span>

  <div
    mat-menu-item
    class="w-100 d-block pt-1"
    *ngFor="let curTheme of themes"
    [matTooltip]="curTheme.id === selectedTheme?.id && curTheme.name !== selectedTheme?.name ? 'Selected theme, but edited.' : ''"
  >
    <button
      class="w-100 d-flex align-items-center px-0 border-0 bg-transparent cursor-pointer"
      [disabled]="selectedTheme ? selectedTheme.id === curTheme.id && !editProjectTheme : false"
      (click)="onSetTheme(curTheme)"
    >
      <mat-icon *ngIf="curTheme.id === selectedTheme?.id && curTheme.name === selectedTheme?.name">radio_button_checked</mat-icon>
      <mat-icon *ngIf="curTheme.id === selectedTheme?.id && curTheme.name !== selectedTheme?.name" matTooltip="Selected theme, but edited."
        >edit</mat-icon
      >
      <mat-icon *ngIf="curTheme.id !== selectedTheme?.id">radio_button_unchecked</mat-icon>
      <span class="mat-body-1 pe-3 mb-0">{{ curTheme.name }}</span>
      <div class="ms-auto">
        <mat-icon class="me-0">
          <lsf-themes-svg-preview [theme]="curTheme"></lsf-themes-svg-preview>
        </mat-icon>
      </div>
    </button>
  </div>
</mat-menu>
