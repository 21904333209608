import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoles } from '@shared/util/code';
import { UserService } from '@shared/util/user';

@Injectable({
  providedIn: 'root',
})
export class CanAccessPortalGuard {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  canActivate(): boolean {
    return this.route();
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }

  private route() {
    if (this.userService.currentOrImpersonateUser.role === UserRoles.User) {
      this.router.navigateByUrl(`/user-dashboard`).then();
      return false;
    }
    return true;
  }
}
