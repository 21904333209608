import { Pipe, PipeTransform } from '@angular/core';

/**
 * Replace each occurence of one or more characters in a string.
 * Pipe of `string.replace(g)` function.
 *
 * @example
 * {{ 'hello' | replace: 'e': 'a' }}
 */
@Pipe({
  name: 'replace',
  standalone: true,
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, from: string, to: string): string {
    // eslint-disable-next-line no-useless-escape
    return value.replace(new RegExp(from.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), to);
  }
}
